var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"_content"},[_c('a-row',[_c('a-col',{attrs:{"xs":{ span: 20, offset: 2 },"lg":{ span: 14, offset: 5 }}},[_c('section',[_c('a-row',[_c('a-row',[_c('a-col',{attrs:{"lg":{ span: 10 }}},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/img/logodot.png")}})])]),(_vm.successpage)?_c('a-col',{attrs:{"lg":{ span: 10 }}},[_c('div',{staticClass:"successpage"},[_c('a-icon',{attrs:{"type":"check-circle","theme":"filled"}}),_c('h3',[_vm._v("Sukses !")]),_c('p',[_vm._v("Silahkan periksa email anda. Terimakasih")])],1)]):_c('a-col',{attrs:{"lg":{ span: 14 }}},[(_vm.register)?_c('div',[_c('div',{staticClass:"successpage"},[_c('a-icon',{attrs:{"type":"warning","theme":"twoTone","two-tone-color":"#ff0000"}}),_c('h3',[_vm._v("Informasi !")]),_c('p',[_vm._v("Pendaftaran ditutup Sementara. Terimakasih")])],1)]):_c('div',{staticClass:"register"},[_c('span',[_vm._v("Create a new account")]),_c('h1',[_vm._v("Daftar Disini!")]),_c('a-form',{staticClass:"formregis",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":15}},[_c('a-col',{attrs:{"lg":{ span: 12 }}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'fullName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'fullName',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"Nama Lengkap"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'email',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"autocomplete":"off","type":"email","placeholder":"Email Address"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'jabatan',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'jabatan',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"type":"text","placeholder":"Pilih Jabatan"}},[_c('a-select-option',{attrs:{"value":"Direktur Fungsi Hukum"}},[_vm._v("Direktur Fungsi Hukum")]),_c('a-select-option',{attrs:{"value":"Pimpinan Fungsi Hukum"}},[_vm._v("Pimpinan Fungsi Hukum")]),_c('a-select-option',{attrs:{"value":"Pekerja Legal"}},[_vm._v("Pekerja Legal")])],1)],1)],1),_c('a-col',{attrs:{"lg":{ span: 12 }}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'company',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"Perusahaan"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"bank"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'telp',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'telp',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"No.Telp"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"phone"},slot:"prefix"})],1)],1),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"link","html-type":"submit","loading":_vm.loading}},[_vm._v(" Sign Up ")])],1)],1)],1),_c('small',[_vm._v("* "),_c('i',[_vm._v("Maksimal 3 perwakilan dari masing-masing BUMN dan Anak Perusahaan BUMN")])])],1)])],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }