<template>
  <div class="_content">
    <a-row>
      <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 14, offset: 5 }">
        <section>
          <a-row>
            <a-row>
              <a-col :lg="{ span: 10 }">
                <div class="title">
                  <img src="@/assets/img/logodot.png" />
                </div>
              </a-col>
              <a-col :lg="{ span: 10 }" v-if="successpage">
                <div class="successpage">
                  <a-icon type="check-circle" theme="filled" />
                  <h3>Sukses !</h3>
                  <p>Silahkan periksa email anda. Terimakasih</p>
                </div>
              </a-col>
              <a-col :lg="{ span: 14 }" v-else>
                <div v-if="register">
                  <div class="successpage">
                    <a-icon
                      type="warning"
                      theme="twoTone"
                      two-tone-color="#ff0000"
                    />
                    <h3>Informasi !</h3>
                    <p>Pendaftaran ditutup Sementara. Terimakasih</p>
                  </div>
                </div>
                <div class="register" v-else>
                  <span>Create a new account</span>
                  <h1>Daftar Disini!</h1>
                  <a-form :form="form" @submit="handleSubmit" class="formregis">
                    <a-row :gutter="15">
                      <a-col :lg="{ span: 12 }">
                        <a-form-item>
                          <a-input
                            autocomplete="off"
                            v-decorator="[
                              'fullName',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]"
                            type="text"
                            placeholder="Nama Lengkap"
                          >
                            <a-icon
                              slot="prefix"
                              type="user"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input>
                        </a-form-item>

                        <a-form-item>
                          <a-input
                            autocomplete="off"
                            v-decorator="[
                              'email',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]"
                            type="email"
                            placeholder="Email Address"
                          >
                            <a-icon
                              slot="prefix"
                              type="mail"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input>
                        </a-form-item>

                        <a-form-item>
                          <a-select
                            v-decorator="[
                              'jabatan',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]"
                            type="text"
                            placeholder="Pilih Jabatan"
                          >
                            <a-select-option value="Direktur Fungsi Hukum"
                              >Direktur Fungsi Hukum</a-select-option
                            >
                            <a-select-option value="Pimpinan Fungsi Hukum"
                              >Pimpinan Fungsi Hukum</a-select-option
                            >
                            <a-select-option value="Pekerja Legal"
                              >Pekerja Legal</a-select-option
                            >
                          </a-select>
                        </a-form-item>
                      </a-col>
                      <a-col :lg="{ span: 12 }">
                        <a-form-item>
                          <a-input
                            autocomplete="off"
                            v-decorator="[
                              'company',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]"
                            type="text"
                            placeholder="Perusahaan"
                          >
                            <a-icon
                              slot="prefix"
                              type="bank"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input>
                        </a-form-item>

                        <a-form-item>
                          <a-input
                            autocomplete="off"
                            v-decorator="[
                              'telp',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]"
                            type="text"
                            placeholder="No.Telp"
                          >
                            <a-icon
                              slot="prefix"
                              type="phone"
                              style="color: rgba(0, 0, 0, 0.25)"
                            />
                          </a-input>
                        </a-form-item>

                        <!-- <a-form-item>
                          <a-select
                            v-decorator="[
                              'opsi',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]"
                            type="password"
                            placeholder="Pilih Opsi"
                          >
                            <a-select-option value="1"
                              >Hadir di Lokasi</a-select-option
                            >
                            <a-select-option value="2">Daring</a-select-option>
                          </a-select>
                        </a-form-item> -->

                        <a-button
                          type="link"
                          html-type="submit"
                          class="login-form-button"
                          :loading="loading"
                        >
                          Sign Up
                        </a-button>

                        <!-- <a-row>
                          <a-col :lg="{ span: 14, offset: 10 }">
                            <div class="alreadyaccount">
                              Already have an account?
                              <router-link to="#">Sign in</router-link>
                            </div>
                          </a-col>
                        </a-row> -->
                      </a-col>
                    </a-row>
                  </a-form>

                  <small
                    >*
                    <i
                      >Maksimal 3 perwakilan dari masing-masing BUMN dan Anak
                      Perusahaan BUMN</i
                    >
                  </small>
                </div>
              </a-col>
            </a-row>
          </a-row>
        </section>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },

  data() {
    return {
      loading: false,
      successpage: false,
      showregister: false,
      register: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    ...mapState(["token"]),
    ...mapGetters(["isAuth"]),
  },

  created() {
    if (this.isAuth == true) {
      this.showregister = true;
    }

    this.fetchSetting();
  },
  methods: {
    fetchSetting() {
      this.$store
        .dispatch("Settings", {
          label: "register",
        })
        .then((response) => {
          if (response.data.register == 1) {
            this.register = true;
          }
        });
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          //console.log(values);
          this.loading = true;
          this.$store
            .dispatch("RegisterUser", {
              ...values,
            })
            .then((response) => {
              this.loading = true;
              this.successpage = true;
              this.form.resetField();
              //console.log(response);
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
